<template>
    <div class="container_vagas h-100">

        <LayotTabela :colunasTabelas="colunasTabelaVagas">

            <template #breadcrumb>
                <Breadcrumb titulo="VAGAS" :items="itensBreadcrumb" backgroundColor="dodgerblue" />
            </template>

            <template #menu>
                <HeaderTabela>
                    <template #area1>
                        <div class="d-flex">
                            <b-form-input class="tabela-clientes-input-data mr-2"
                                          type="search"
                                          placeholder="Pesquise por nome..."
                                          v-model="nomeFiltrado"
                                          tabindex="1"
                                          @keyup.enter="buscarPagina">
                            </b-form-input>

                            <botao-padrao texto="Filtrar"
                                          class="tabela-clientes-botao-filtrar"
                                          @click="buscarPagina" />
                        </div>
                    </template>
                    <template #area4>
                        <BotaoPadrao @click="abrirModalCriacao" v-if="perfisPermitidos.some(perfilPermitido => perfilPermitido === dadosUsuario.perfil.toLowerCase())">Nova vaga</BotaoPadrao>
                    </template>
                </HeaderTabela>
            </template>

            <template #tabela="{ classesPadrao, propsPadrao, colunasEstiloPadrao }">
                <b-table v-bind="propsPadrao"
                         class="ajustes-fontes-colunas"
                         :busy="loadingTabela"
                         :class="'py-2 ' + classesPadrao"
                         :fields="colunasEstiloPadrao"
                         :items="listaVagas">

                    <template #table-busy>
                        <div class="text-center py-2">
                            <b-spinner class="align-middle ml-1"></b-spinner>
                            <strong class="px-1">Carregando...</strong>
                        </div>
                    </template>

                    <template #cell(status)="{ item }">
                        <b-badge :variant="['warning', 'success', 'danger'][item.Status]"
                                 tag="strong"
                                 class="rounded-0 p-1">
                            {{['Aberta', 'Fechada', 'Cancelada'][item.Status]}}
                        </b-badge>
                    </template>

                    <template #cell(acoes)="{ item }">
                        <BotaoTabEdit @click="abrirModalEdicao(item.Id)" />
                        <BotaoTabRemove @click="removerVaga(item.Id)" />
                    </template>

                </b-table>
            </template>

            <template #paginacao>
                <PaginacaoTabela @click="trocarPagina"
                                 :totalDePaginas="totalDePaginas"
                                 :numeroDaPaginaAtual="nroPaginaAtual"
                                 :totalDeResultados="totalDeResultados"
                                 :loading="loadingTabela" />
            </template>

        </LayotTabela>


        <ModalVaga ref="modalVaga"
                   :vaga="vagaSelecionada"
                   @salvar="salvarVaga" />
    </div>
</template>

<script>
    const LayotTabela = () => import('@/components/tabelas/LayotTabela.vue')
    const HeaderTabela = () => import('@/components/tabelas/HeaderTabela.vue')
    const PaginacaoTabela = () => import('@/components/tabelas/paginacao/PaginacaoTabela.vue')
    const Breadcrumb = () => import('@/components/breadcrumb/Breadcrumb.vue')
    const ModalVaga = () => import('@/components/cliente/ModalVaga.vue')
    const BotaoPadrao = () => import('@/components/botoes/BotaoPadrao.vue')
    const BotaoTabEdit = () => import('@/components/botoes/BotaoTabEdit.vue')
    const BotaoTabRemove = () => import('@/components/botoes/BotaoTabRemove.vue')

    import VagaServices from '@/assets/js/services/VagaServices'
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'VagasPage',

        components: {
            LayotTabela,
            HeaderTabela,
            PaginacaoTabela,
            ModalVaga,
            Breadcrumb,
            BotaoPadrao,
            BotaoTabEdit,
            BotaoTabRemove
        },

        data: function () {
            //autorizados: ['master', 'comercial', 'supervisor', 'gestor'];
            //let colunasTabelasVagas = ;
            //if (autorizados.some(perfilPermitido => perfilPermitido === this.dadosUsuario.perfil.toLowerCase())) colunasTabelasVagas.push({ key: 'acoes', label: 'Ações', tdStyle: 'width: 100px' });
            return {
                itensBreadcrumb: [
                    {
                        id: '1',
                        classe: 'fas fa-search',
                        texto: 'Cadastros Básicos',
                        link: '/cadastros-basicos'
                    },
                    {
                        id: '2',
                        classe: 'fas fa-users',
                        texto: 'Relações clientes',
                        link: '/relacoes-clientes'
                    },
                    {
                        id: '3',
                        classe: 'fas fa-user-tie',
                        texto: 'Cadastro de Vagas',
                        link: '',
                    },
                ],
                perfisPermitidos: ['master', 'comercial', 'supervisor', 'gestor'],
                colunasTabelaVagas: [
                    { key: 'Cliente.NomeFantasia', label: 'Cliente', sortable: true, sortDirection: 'asc' },
                    { key: 'Nome', label: 'Título da vaga', sortable: true, sortDirection: 'asc' },
                    { key: 'FaixaSalarial', label: 'Salário', sortable: true, sortDirection: 'asc' },
                    { key: 'ModeloTrabalho', label: 'Modelo de trabalho', sortable: true, sortDirection: 'asc' },
                    { key: 'Status', label: 'Status', sortable: true, sortDirection: 'asc' }
                ],
                listaVagas: [],
                vagaSelecionada: null,

                totalDeResultados: 0,
                nroPaginaAtual: 1,
                tamanhoPagina: 20,
                loadingTabela: false,
                filtrosAtuais: null,
                nomeFiltrado: ''
            }
        },

        watch: {
            nroPaginaAtual() {
                this.buscarPagina()
            },
        },

        computed: {
            ...mapGetters({
                dadosUsuario: 'authentication/authDadosUsuario'
            }),
            totalDePaginas() {
                let qtdePaginas = Math.floor(this.totalDeResultados / this.tamanhoPagina)
                if ((this.totalDeResultados % this.tamanhoPagina) > 0) {
                    qtdePaginas++
                }
                return qtdePaginas
            },
        },

        methods: {
            ...mapActions({
                setLoadingGlobal: 'setLoadingGlobal',
                mostrarSucesso: 'mostradorDeMensagens/mostrarSucesso',
                mostrarErro: 'mostradorDeMensagens/mostrarErro',
            }),
            abrirModalCriacao() {
                this.vagaSelecionada = null
                this.$nextTick(() => {
                    this.$refs.modalVaga.show()
                })
            },
            async abrirModalEdicao(vagaId) {
                try {
                    this.loadingTabela = true
                    const resultado = await VagaServices.getById(vagaId)
                    this.vagaSelecionada = resultado
                    // Evita problemas de reatividade, vagaSelecionado é passado por prop para o modal.
                    this.$nextTick(() => {
                        this.$refs.modalVaga.show()
                    })
                } catch (err) {
                    console.log('erro getById ', err)
                    this.mostrarErro('Ocorreu um erro inesperado ao consultar as informações do vaga')
                }
                this.loadingTabela = false
            },
            removerVaga(id) {
                this.$bvModal.msgBoxConfirm('Tem certeza que deseja excluir esta vaga?', {
                    okTitle: 'Confirmar',
                    cancelTitle: 'Cancelar',
                    centered: true
                })
                    .then(async confirmado => {
                        if (confirmado) {
                            await VagaServices.deleteById(id)
                          
                            this.$bvToast.toast('Excluído com sucesso', {
                                title: `Aviso`,
                                toaster: 'b-toaster-top-right',
                                solid: true,
                                variant: 'success',
                                appendToast: false,
                                autoHideDelay: 3000,
                                noCloseButton: true
                            })
                            this.buscarPagina()
                        }
                    })
                    .catch(err => {
                        this.mostrarErro('Erro na exclusão do vaga')
                        console.log('erro confirmacao', err)
                    })
            },
            async salvarVaga(vaga) {
                if (!vaga) {
                    console.log('Representante vazio')
                    return
                }
                
                if (vaga.Id) {
                    try {
                        await VagaServices.update(vaga)
                        this.$bvToast.toast('Salvo com sucesso', {
                            title: `Aviso`,
                            toaster: 'b-toaster-top-right',
                            solid: true,
                            variant: 'success',
                            appendToast: false,
                            autoHideDelay: 3000,
                            noCloseButton: true
                        })
                        
                    } catch (ex) {
                        console.log('erro update ', ex)
                        this.mostrarErro('Ocorreu um erro ao salvar o vaga')
                    }
                    this.buscarPagina()
                    return
                }

                try {
                    var response = await VagaServices.post(vaga)

                    if (response.Status == 500) throw new DOMException(response.Message)
                    this.buscarPagina()
                    this.$bvToast.toast('Salvo com sucesso', {
                        title: `Aviso`,
                        toaster: 'b-toaster-top-right',
                        solid: true,
                        variant: 'success',
                        appendToast: false,
                        autoHideDelay: 3000,
                        noCloseButton: true
                    })
                } catch (ex) {
                    console.log('erro post', ex)
                    this.mostrarErro(ex.message)
                }
                this.buscarPagina()
            },
            trocarPagina(nroPagina) {
                this.nroPaginaAtual = nroPagina;
            },
            buscarPagina() {
                this.loadingTabela = true

                VagaServices.getPagina({
                    nomeFiltrado: this.nomeFiltrado,
                    paginaAtual: this.nroPaginaAtual,
                    tamanhoPagina: this.tamanhoPagina
                })
                    .then(resultado => {
                        this.listaVagas = resultado.List;
                        this.totalDeResultados = resultado.TotalResults
                        this.loadingTabela = false

                    }).catch(error => {
                        console.log('error: ', error)
                        this.loadingTabela = false
                    })
            }
        },
        created() {
            if (this.perfisPermitidos.some(perfilPermitido => perfilPermitido === this.dadosUsuario.perfil.toLowerCase())) this.colunasTabelaVagas.push({ key: 'acoes', label: 'Ações', tdStyle: 'width: 100px' });
            this.buscarPagina()
        }
    }
</script>

<style scoped>
    .container_vagas {
        font-size:12px;
    }
    .tabela-clientes-input-data {
        width: 35vh;
        height: 36px !important;
        border-radius: 0;
        box-shadow: none;
    }

    .tabela-clientes-acoes-item-esquerda {
        float: left;
        margin-bottom: 10px;
    }

    .tabela-clientes-botao-filtrar {
        width: 140px;
    }
</style>